<template>
  <div class="edit-container">
    <el-form label-width="110px" class="edit-form" :model="formData" :rules="formRules" ref="form">
      <el-form-item label="用户名：" prop="userName">
        <el-input  v-model="formData.userName" style="width:200px;"></el-input>
      </el-form-item>

      <el-form-item label="手机号：" prop="phone">
        <el-input  v-model="formData.phone" style="width:200px;">></el-input>
      </el-form-item>

      <el-form-item label="角色：" prop="roleId">
        <el-select v-model="formData.roleId" placeholder="请选择" style="width:200px;">
          <el-option
            v-for="item in roles"
            :key="item.roleId"
            :label="item.roleName"
            :value="item.roleId">
          </el-option>
        </el-select>
      </el-form-item>
      
      <el-form-item label="状态：" prop="userStatus">
        <el-radio v-model="formData.userStatus" label="NORMAL" border >正常</el-radio>
        <el-radio v-model="formData.userStatus" label="DISABLE" border >停用</el-radio>
      </el-form-item>

      <el-form-item label="描述" prop="description">
        <el-input type="textarea" v-model="formData.description" placeholder="请输入50字以内" maxlength="50"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" :loading="loading" @click="handleSubmit">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {getRoles} from '@/api/auth'
import {getUserDetail, createUser, updateUser} from '@/api/user'

export default {
  data() {
    const validatePhone = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请输入手机号'))
      } else {
        callback()
      }
    }
    return {
      userId: parseInt(this.$route.query.userId),
      roles:[],
      formData:{},
      loading: false,
      formRules: {
        userName: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        phone: [{ required: true, trigger: 'blur', pattern: /^1[345789]\d{9}$/, message: '请输入正确的手机号' }],
        roleId: [{ required: true, message: '请选择角色', trigger: 'blur' }],
        userStatus: [{ required: true, message: '请选择状态', trigger: 'blur' }],
        description: [{ required: true, message: '请输入描述', trigger: 'blur' }]
      }
    }
  },
  mounted() {
    this.$refs.form.clearValidate()
    this.getRoles()
    if(this.userId){
      this.getDetail()
    }
  },
  methods: {
    getRoles() {
      getRoles().then(res => {
        this.roles = res.data.result
      })
    },
    getDetail() {
      getUserDetail({userId:this.userId}).then(res => {
        const {role,...oth} = res.data
        this.formData = oth
        this.formData.roleId = res.data.role.roleId
      })
    },
    handleSubmit() {
      this.$refs.form.validate(async valid => {
        if(valid) {
          this.loading = true
          try {
            let userId
            if(this.userId){  // 修改
              this.formData.userId = this.userId
              await updateUser(this.formData)
              userId = this.userId
            } else{ // 新建
              await createUser(this.formData).then(res => {
                userId = res.data.result
              })
            }
            this.loading = false
            this.$message({
              message: '保存成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                const path = this.$route.matched.slice(this.$route.matched.length-2, this.$route.matched.length-1)[0].path
                this.$router.push(path+'/detail?userId='+userId)
              }
            })
          } catch {
            this.loading = false
          }
        } else {
          return false
        }
      })
      
    }
  }
}
</script>
